import React from 'react'

export default function Team() {
    let Teams = [
        {name:"Akki Sorathiya",img:"images/home/team.png"},
        {name:"Jayesh Suthar",img:"images/home/team.png"},
        {name:"Neel Borad",img:"images/home/team.png"},
        {name:"Sunny Patel",img:"images/home/team.png"},
    ];
  return (
    <>
      <section class="relative my-8 py-10 text-brand-dark">
        <div class="mx-auto text-center px-2 sm:px-12 lg:px-16">
            <div>
                <h2 class="text-4xl font-bold">Team</h2>
            </div>
            <div class="text-center font-medium tracking-wide">
                <p>We will guide you into the digital world. Our main goal is your constant growth in the market and success.</p>
            </div>
            <div class="mt-8 grid grid-cols-12">
            {
                Teams.map((team)=>(
                    <div class="col-span-12 sm:col-span-6 py-3 lg:col-span-4 px-3.5">
                        <div class="group overflow-hidden p-12 text-center border-2 rounded-full relative">
                            <img src={team.img} alt="" />
                        
                        <div class="content px-5 p-5 py-3">
                            <h3 class="text-2xl font-medium">
                                <a href="#" tabindex="-1">{team.name}</a>
                            </h3>
                        </div>
                        </div>
                    </div>
                ))
            }
            </div>
        </div>
    </section>
    </>
  )
}
