import React, { useState } from "react";
import { FaBeer } from 'react-icons/fa';
import { AiOutlineMenu,AiOutlineClose } from "react-icons/ai";


function Navbar({ isScrolled }) {
    let Links = [
        {name:"Home",link:"/"},
        {name:"Services",link:"/services"},
        {name:"About",link:"/about"},
        {name:"Contact",link:"/contact"},
        {name:"Meet The Team",link:"/meet-the-team"},
    ];
    let [open,setOpen] = useState(false);
return (
    <>
        <div className={`top-0 left-0 fixed shadow-sm w-full ${isScrolled ? 'sticky top-0 z-50 duration-1000' : ''}`}>
            <div className={`md:flex items-center justify-between py-4 md:px-10 px-7 ${isScrolled ? 'bg-white' : 'bg-white'} `}>
                <div className="flex font-bold text-2xl cursor-pointer items-center font-[Poppins] text-dark-purple">
                    {/* <span className="text-3xl text-dark-purple mr-1"><FaBeer /></span>
                    Black4est */}
                    <img src="images/logo/w-logo.png" className="w-1/4 lg:w-1/3 h-full" />
                </div>
                <div className="text-3xl text-dark-purple absolute right-8 top-6 cursor-pointer md:hidden" onClick={() => setOpen(!open)}>
                    {open?<AiOutlineClose/>:<AiOutlineMenu/>}
                </div>
                <ul className={`md:flex md:items-center md:pb-0 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 translate-all duration-500 ease-in ${open ? "opacity-100 mt-3 border-t-8 border-dark-purple" : "top-[-490px] md:opacity-100 opacity-0"}`}>
                    {   
                        Links.map((link)=>(
                            <li className="md:ml-8 md:text-xl md:my-0 my-7">
                                <a className="text-dark-purple hover:text-gray-700 duration-500" href={link.link}>{link.name}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </>
  );
}

export default Navbar;