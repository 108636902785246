import React from 'react'

export default function Contact() {
  return (
    <>
    <section class="py-10 bg-white text-dark-purple">
        <div class="mx-auto text-center px-2 sm:px-12 lg:px-20">
            <div>
                <h2 class="text-3xl lg:text-5xl font-bold">Want to Know More?</h2>
            </div>
            <div class="mt-3.5">
                <p class="text-lg tracking-wide">If we've piqued your interest, there is no time like the present! Fill in the form below for a free consultation.</p>
            </div>
            <div class="mt-8 grid grid-cols-12">
                <div class="col-span-12 lg:col-span-6">
                    <div class="">
                        <img src="images/home/contact.jpg" alt=""/>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-1"></div>
                <div class="col-span-12 lg:col-span-5">
                    <div class="form bg-white py-14 px-11 rounded-md">
                        <div class="flex flex-col text-black gap-y-5">
                            <div class="">
                                <input name="username" class="w-full ring ring-dark-purple rounded-md py-2 px-2" type="text" placeholder="Name" />
                            </div>
                            <div class="">
                                <input name="email" class="w-full ring ring-dark-purple rounded-md py-2 px-2" type="email" placeholder="Email" />
                            </div>
                            <div class="">
                                <input name="phone" class="w-full ring ring-dark-purple rounded-md py-2 px-2" type="text" placeholder="Contact Number" />
                            </div>
                            <div class="">
                                <input name="subject" class="w-full ring ring-dark-purple rounded-md py-2 px-2" type="text" placeholder="Your Subject" />
                            </div>
                            <div class="">
                                <textarea name="msg" class="w-full ring ring-dark-purple rounded-md py-2 px-2" type="text" placeholder="Message"></textarea>
                            </div>
                            <button type="submit" class="bg-gradient-to-b ring ring-dark-purple rounded-md hover:text-dark-purple hover:bg-white bg-dark-purple to-dark-blue hover:from-brand-dark hover:to-brand-dark text-center text-white py-3">
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
