import React from "react";

const Services = () => {
    let Services = [
        {title:"Search Engine Optimization",link:"/#",img:"images/home/service-1.png"},
        {title:"Social Media Marketing",link:"/#",img:"images/home/service-1.png"},
        {title:"Pay Per Click",link:"/#",img:"images/home/service-1.png"},
        {title:"Content Writing",link:"/#",img:"images/home/service-1.png"},
        {title:"Lead Generation",link:"/#",img:"images/home/service-1.png"},
        {title:"Email Marketing",link:"/#",img:"images/home/service-1.png"},
    ];
  return (
    <section class="relative mt-2 py-10 text-brand-dark">
      <div class="mx-auto text-center px-2 sm:px-8 lg:px-16">
        <div>
          <h2 class="text-4xl font-bold">Services That We Provide</h2>
        </div>
        <div class="mt-3.5">
          <p class="text-lg tracking-wide">
            Our international team has extensive experience in all digital
            niches. Let us take you through our world of digital marketing.
          </p>
        </div>
        <div class="mt-8 grid grid-cols-12">
            {
                Services.map((Service)=>(
                    <div class="col-span-12 sm:col-span-6 lg:col-span-4 px-3.5">
                        <div class="group overflow-hidden p-12 text-center border-2 rounded-full relative">
                            <img src={Service.img} alt="" />
                        </div>
                        <div class="px-5 p-5">
                            <h3 class="text-2xl font-semibold">
                                <a href={Service.link}>{Service.title}</a>
                            </h3>
                        </div>
                    </div>
                ))
            }
        </div>
      </div>
    </section>
  );
};

export default Services;
