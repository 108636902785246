import './App.css';
import Navbar from './Navbar';
// import Header from './Header';
import React, { useState, useEffect } from 'react';
import Scrolling from './Scrolling';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import Home from './component/Home';
import Services from './component/Services';
import Contact from './component/Contact';
import Team from './component/Team';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <div className='text-white w-full md:h-auto h-auto'>
      {/* <Header isScrolled={isScrolled} /> */}
      {/* Add the rest of your app content here */}
      <Navbar isScrolled={isScrolled}/>
        <Home/>
        <Services/>
        <Contact/>
        <Team />
        {/* <Scrolling /> */}
    </div>
    <Footer/>
    <ScrollToTop />
    </>
  );
}

export default App;
