import React from 'react';

function Home() {
  return (
    <>
    <div className='mt-20'>
      {/* Banner */}
      <div className="relative bg-dark-purple z-[-1]">
        <img
          src="https://mycasaaway1.5.sociallyenterprise.com/build/assets/home-banner-bf336e96.jpg"
          alt="Banner"
          className="w-full h-full object-cover opacity-75"
        />
        <div className="absolute inset-0 bg-black opacity-40"></div>
        <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
          <h1 className="text-3xl sm:text-5xl font-bold mb-2 text-center">
            Welcome to our online store
          </h1>
          <p className="text-xl sm:text-2xl mb-8 text-center">
            Shop now and get the best deals
          </p>
          <button className="text-white bg-dark-purple py-2 px-4 ring-1 ring-white hover:ring-dark-purple rounded-lg hover:bg-white hover:text-dark-purple transition-colors duration-300"> Shop Now </button>
        </div>
      </div>

      {/* Search filter */}
      <div className="bg-white py-8">
        <div className="w-full mt-4 grid grid-cols-12 gap-2 justify-between items-center px-12">
            <div className="mb-4 col-span-12 lg:col-span-3">
              <label htmlFor="search" className="sr-only"> Search</label>
              <input type="text" placeholder="Search products..." className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4" />
            </div>
            <div className="mb-4 col-span-12 lg:col-span-3">
              <label htmlFor="category" className="sr-only">Category</label>
              <input
                type="text"
                id="category"
                placeholder="Category..." className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4"
              />
            </div>
            <div className="mb-4 col-span-12 lg:col-span-3">
              <label htmlFor="price" className="sr-only">Price range</label>
              <input type="text" placeholder="Price range.." 
              className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4" />
            </div>
            <div className="mb-4 col-span-12 lg:col-span-3">
                <button className="w-full text-white bg-dark-purple py-2 px-4 ring-1 ring-white hover:ring-dark-purple rounded-lg hover:bg-white hover:text-dark-purple transition-colors duration-300"> Shop Now </button>
            </div>
        </div>
        
      </div>
    </div>

    {/* <section class="bg-[#23304c] h-screen flex px-[10%] py-[6%]">
      <img
        src="images/home/Ellipse.png"
        class="z-20 animate-ping w-6 absolute left-24 top-56"
      />
      <img
        src="images/home/Ellipse.png"
        class="z-20 animate-ping w-6 absolute right-96 top-36"
      />
      <img
        src="images/home/Ellipse.png"
        class="z-20 animate-ping w-6 absolute left-64 bottom-24"
      />
      <img
        src="images/home/Ellipse.png"
        class="z-20 animate-ping w-6 absolute right-40 top-64"
      />

      <img src="images/home/wave3.png" class="absolute bottom-20 right-36 w-96" />
      <img
        src="images/home/wave.png"
        class="absolute z-20 bottom-0 right-0 w-2/3"
      />
      <div class="flex-1">
        <img src="images/home/loading.png" class="w-9/12" />
      </div>

      <div class="flex-1 pt-7">
        <div class="absolute">
          <h1 class="text-3xl leading-normal py-6">
            Build visual <br />
            tracking systems <br />
            for your
            <span class="underline decoration-teal-500">customers</span>
          </h1>
          <button
            class="bg-teal-600 px-7 rounded-full tracking-wide py-3 text-xs hover:scale-110 duration-300"
          >
            GET STARTED
          </button>
        </div>
      </div>
    </section> */}
    </>
  );
}

export default Home;

// import React, { useState } from 'react';

// function Home() {
//   const [isHeaderOpen, setIsHeaderOpen] = useState(false);

//   const handleToggleHeader = () => {
//     setIsHeaderOpen(!isHeaderOpen);
//   };

//   return (
//     <div className="relative">
//       {/* Banner */}
//       <div className="relative bg-gray-800 z-[-1]">
//         <img
//           src="https://mycasaaway1.5.sociallyenterprise.com/build/assets/home-banner-bf336e96.jpg"
//           alt="Banner"
//           className={`w-full h-64 object-cover opacity-75 ${
//             isHeaderOpen ? 'z-0' : 'z-10'
//           }`}
//         />
//         <div className="absolute inset-0 bg-black opacity-40"></div>
//         <div
//           className={`${
//             isHeaderOpen ? 'z-20' : 'z-0'
//           } absolute inset-0 flex flex-col justify-center items-center text-white`}
//         >
//           <h1 className="text-3xl sm:text-5xl font-bold mb-2 text-center">
//             Welcome to our online store
//           </h1>
//           <p className="text-xl sm:text-2xl mb-8 text-center">
//             Shop now and get the best deals
//           </p>
//           <button
//             className="bg-white text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-200 transition-colors duration-300"
//             onClick={handleToggleHeader}
//           >
//             Shop Now
//           </button>
//         </div>
//       </div>

//       {/* Header */}
//       <div
//         className={`${
//           isHeaderOpen ? 'top-0' : '-top-full'
//         } bg-gray-800 text-white w-full fixed z-30 transition-all duration-300`}
//       >
//         <div className="container mx-auto px-4 py-2 flex justify-between items-center">
//           <div>
//             <h1 className="text-2xl font-bold">My Store</h1>
//           </div>
//           <div>
//             <button onClick={handleToggleHeader}>Close</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;
