// import React from 'react';

// const Footer = () => {
//   return (
//     <footer className="bg-gray-900 text-gray-100 md:mt-0 mt-0">
//       <div className="max-w-6xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between">
//         <div>
//           <h4 className="font-bold mb-2">About Us</h4>
//           <p className="text-sm">We are a company that specializes in creating beautiful websites and applications using the latest technologies.</p>
//         </div>
//         <div>
//           <h4 className="font-bold mb-2">Contact Us</h4>
//           <p className="text-sm">123 Main Street<br />New York, NY 10001<br />hello@example.com<br />(123) 456-7890</p>
//         </div>
//         <div>
//           <h4 className="font-bold mb-2">Follow Us</h4>
//           <div className="flex">
//             <a href="#" className="text-gray-100 hover:text-gray-300 mr-4"><i className="fab fa-facebook fa-lg"></i></a>
//             <a href="#" className="text-gray-100 hover:text-gray-300 mr-4"><i className="fab fa-twitter fa-lg"></i></a>
//             <a href="#" className="text-gray-100 hover:text-gray-300 mr-4"><i className="fab fa-instagram fa-lg"></i></a>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from 'react';
// import CiFacebook from 'react-icons/ci'

function Footer() {
  return (
    // <footer className="bg-white text-dark-purple">
    //   <div className="container mx-auto py-4 px-6 flex flex-wrap items-center justify-between">
    //     <div className="w-full md:w-auto mb-4 md:mb-0">
    //       <p className="text-center md:text-left ">&copy; 2023 <span className='font-bold'>BLACK4est </span>. All Rights Reserved.</p>
    //     </div>
    //     {/* <div className="flex ">
    //       <ul className="flex text-xl font-bold">
    //         <li className="mr-4">
    //           <a href="#" className="hover:text-gray-400">
    //             Home
    //           </a>
    //         </li>
    //         <li className="mr-4">
    //           <a href="#" className="hover:text-gray-400">
    //             About Us
    //           </a>
    //         </li>
    //         <li className="mr-4">
    //           <a href="#" className="hover:text-gray-400">
    //             Contact Us
    //           </a>
    //         </li>
    //       </ul>
    //     </div> */}
    //   </div>
    //   {/* <div className="bg-white py-8">
    //     <div className="w-full mt-4 grid grid-cols-12 gap-2 justify-between items-center px-12">
    //         <div className="mb-4 col-span-12 lg:col-span-3">
    //           <label htmlFor="search" className="sr-only"> Search</label>
    //           <input type="text" placeholder="Search products..." className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4" />
    //         </div>
    //         <div className="mb-4 col-span-12 lg:col-span-3">
    //           <label htmlFor="category" className="sr-only">Category</label>
    //           <input
    //             type="text"
    //             id="category"
    //             placeholder="Category..." className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4"
    //           />
    //         </div>
    //         <div className="mb-4 col-span-12 lg:col-span-3">
    //           <label htmlFor="price" className="sr-only">Price range</label>
    //           <input type="text" placeholder="Price range.." 
    //           className="w-full ring-2 text-dark-purple ring-dark-purple rounded-lg py-2 px-4" />
    //         </div>
    //         <div className="mb-4 col-span-12 lg:col-span-3">
                
    //         </div>
    //     </div>
    //   </div> */}
    //   {/* </div> */}

      
    // </footer>
    <>
    <footer className='border-t-2 bg-white text-dark-purple'>
      <section class="py-2 bg-light-blue sm:pt-16 lg:pt-2">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-6xl">
            <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-y-4 gap-x-8 xl:gap-x-4">
                <div class="lg:col-span-4 col-span-12 md:col-span-4 items-start">
                    <img class="w-1/4 h-1/4" src="images/logo/w-logo.png" alt=""/>
                    <h4 class="text-lg font-bold py-5">About <span className='font-[Poppins]'>BLACK4EST</span></h4>
                    <p class="text-sm leading-relaxed pl-5 lg:pl-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <div className='lg:mb-10'>
                    <p class="text-base font-bold py-5">Follow us</p>
                    <ul class="flex items-center gap-x-2">
                        <li>
                            <a href="#" title="" class="flex items-center justify-center text-white transition-all duration-200 bg-dark-purple rounded-full w-7 h-7">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                                </svg>
                            </a>
                        </li>
        
                        <li>
                            <a href="#" title="" class="flex items-center justify-center text-white transition-all duration-200 bg-dark-purple rounded-full w-7 h-7">
                                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                                    <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="lg:col-span-3 col-span-6">
                    <p class="text-base font-bold">Usefull Links</p>
                    <ul class="mt-1">
                        <li>
                            <a href="#" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Home</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                              <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Services</span>
                            </a>
                        </li>

                        <li>
                            <a href="#" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">About</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                              <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">contact</span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                              <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Meet The Team</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="lg:col-span-3 col-span-6">
                    <p class="text-base font-bold">Policies</p>
                    <ul class="mt-1">
                        <li>
                            <a href="" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Cookie Policy</span>
                            </a>
                        </li>
                        <li>
                            <a href="" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Privacy Policy</span>
                            </a>
                        </li>
                        <li>
                            <a href="" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">GDPR Policies</span>
                            </a>
                        </li>
                        <li>
                            <a href="" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Terms & Conditions</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="lg:col-span-2 col-span-6">
                    <p class="text-base font-bold">Our Services</p>
                    <ul class="mt-1">
                        <li>
                            <a href="" class="inline-flex items-center justify-start py-2 pr-2 overflow-hidden transition-all duration-150 ease-in-out rounded hover:pl-4 hover:pr-2 group">
                                <span class="w-full text-left transition-colors text-sm duration-50 ease-in-out hover:text-brand-dark">Seo</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    </footer>
    <div className='bg-dark-purple text-center text-white py-3'>
        <p className="text-center">&copy; 2000 <span className='font-bold font-[Poppins]'>BLACK4EST </span>. All Rights Reserved.</p>
    </div>
    </>
  );
}

export default Footer;